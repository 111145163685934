@import "~antd/dist/antd.css";
/* @import "node_modules/video-react/dist/video-react.css"; */

@font-face {
  font-family: "Myriad Pro Semibold";
  src: url("./fonts/Myriad Pro Semibold.otf");
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.buttonColor {
  /* font-family: "Myriad Pro Regular";
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  border-color: rgb(108, 188, 135) !important;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  padding: 15px;
  text-decoration: none;
  min-width: 75px;
  height: 55px;
  display: inline-block; */

  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  padding: 15px;
  text-decoration: none;
  min-width: 115px;
  display: inline-block;
}

.buttonColor:hover {
  background: rgb(108, 188, 135);
  background: linear-gradient(270deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  text-decoration: none;
}

.buttonColor:active {
  background: rgb(108, 188, 135);
  background: linear-gradient(270deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  text-decoration: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(108, 188, 135) !important;
}

.ant-tabs-tab:hover {
  color: rgb(108, 188, 135) !important;
}

.ant-tabs-tab {
  font-size: 20px !important;
}

.ant-input:focus,
.ant-input-focused {
  font-family: "Myriad Pro Regular";
  color: rgb(108, 188, 135) !important;
}

.cardStyle {
  padding: 25px;
  border: 3px solid #6cbc87 !important;
  border-radius: 30px;
  margin-top: 30px;
}

.profile-btn {
  height: 50px;
  font-size: 20px;
  padding: 4px;
  width: 30px;
  margin-left: 34%;
}

.ant-spin-dot-item {
  background-color: #6cbc87 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #6cbc87 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #6cbc87 !important;
}

h3 {
  font-family: "Myriad Pro Semibold";
}

.thetitleof {
  font-family: "Myriad Pro Semibold";
}

.fundstitle {
  font-family: "Myriad Pro Semibold";
}

.stepsFormStyle {
  margin: 0 auto;
  display: block;
}

.mileStoneCardStyle {
  padding: 25px;
  border: 3px solid #6cbc87 !important;
  border-radius: 30px;
  width: 500px;
  margin-top: 75px !important;
}

* {
  animation-duration: 0s !important;
}

.cardStyleHome {
  padding: 10px;
  border: 3px solid #6cbc87 !important;
  border-radius: 10px;
  width: 95%;
}

.contactBtn {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  border-radius: 100px;
  padding: 6px !important;
  text-decoration: none;
  min-width: 90px;
  display: inline-block;
}
