/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@font-face {
  font-family: "Conv_Myriad Pro Regular";
  src: url("../webfonts/Myriad Pro Regular.eot");
  src: local("☺"), url("../webfonts/Myriad Pro Regular.woff") format("woff"),
    url("../webfonts/Myriad Pro Regular.ttf") format("truetype"),
    url("../webfonts/Myriad Pro Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_Myriad Pro Semibold";
  src: url("../webfonts/Myriad Pro Semibold.eot");
  src: local("☺"), url("../webfonts/Myriad Pro Semibold.woff") format("woff"),
    url("../webfonts/Myriad Pro Semibold.ttf") format("truetype"),
    url("../webfonts/Myriad Pro Semibold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
q,
em,
img,
small,
strong,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}

.btn-responsive-milestone {
  color: white;
  font-size: 20px;
  margin-top: 20px;
  height: 55px;
  position: absolute;
  top: -171px;
}

.anchor-back {
  position: relative;
  top: -90px;
  left: 175px;
  font-size: 16px;
}

ol,
ul {
  list-style: none;
}
:focus {
  outline: 0;
}
input,
textarea {
  margin: 0;
  outline: 0;
}
textarea {
  overflow: auto;
  resize: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* End Reset */

/* html5 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

/* Default Font Styles
______________________*/
body,
input,
select,
textarea {
  font-family: "Conv_Myriad Pro Regular";
  font-size: 14px;
  color: #000;
  line-height: 1.3;
}
.container-fluid {
  max-width: 100%;
  padding: 0 50px;
}

/* Headings
______________________*/
h1 {
  font-family: "Conv_Myriad Pro Semibold";
  font-size: 28px;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

/* Links
______________________*/
a {
  -webkit-transition: 0.1s ease-out 0.1s;
  -moz-transition: 0.1s ease-out 0.1s;
  transition: 0.1s ease-out 0.1s;
}
a:hover {
  text-decoration: underline;
}

.default-btn {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  /* padding: 15px; */
  text-decoration: none;
  min-width: 115px;
  display: inline-block;
  height: 50px;
}
.default-btn:hover {
  background: rgb(108, 188, 135);
  background: linear-gradient(270deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  text-decoration: none;
  color: #fff;
  height: 50px;
}
.grey-btn {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  background: #fff;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  padding: 10px 15px 8px;
  text-decoration: none;
  min-width: 75px;
  color: #000;
  display: inline-block;
}
.grey-btn:hover {
  color: #fff;
  background: #000000;
  text-decoration: none;
}
.grey-default-btn {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;

  min-width: 75px;
  display: inline-block;
  padding: 5px 15px 8px;
  text-decoration: none;
  height: 38px;
}
.grey-default-btn:hover {
  background: rgb(108, 188, 135);
  background: linear-gradient(270deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  text-decoration: none;
  color: #fff;
  min-width: 75px;
  display: inline-block;
}

/* p, blockquote, address
______________________*/
p {
  line-height: 29px;
  margin-bottom: 30px;
  font-size: 16px;
}

#header {
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);
}
#header.active {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);
}
#header.active .top-contactdetail {
  display: none;
}
.header-wrap {
  padding: 25px 0;
}
.header-wrap .header-left {
  float: left;
  width: 210px;
}
.header-wrap .header-left .logo a {
  display: block;
  background: url(../images/logo.png) no-repeat;
  background-size: 100% auto;
  height: 70px;
  margin: 0 auto;
}

.header-wrap .header-left-signup {
  float: left;
  padding-left: 30px;
}
.header-wrap .header-left-signup .logo a {
  display: block;
  background: url(../images/logo.png) no-repeat;
  background-size: 100% auto;
  height: 70px;
  margin: 0 auto;
}
.header-wrap .header-right {
  float: right;
  width: 100%;
  text-align: right;
}
.header-wrap .top-contactdetail {
  padding-bottom: 20px;
}
.header-wrap .top-contactdetail li {
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
}
.header-wrap .top-contactdetail li:last-child {
  margin-left: 40px;
}
.header-wrap .top-contactdetail li a {
  color: #000000;
  text-decoration: none;
  font-size: 18px;
  font-family: "Conv_Myriad Pro Semibold";
}
.header-wrap .top-contactdetail li a:hover {
  color: #51acb4;
}
.header-wrap .menu li {
  display: inline-block;
  vertical-align: middle;
  margin-left: 100px;
}
.header-wrap .menu li:first-child {
  margin: 0;
}
.header-wrap .menu li a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Conv_Myriad Pro Semibold";
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-wrap .menu li a:hover {
  background: rgb(108, 188, 135);
  background: linear-gradient(270deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#header.active .menu {
  padding-top: 15px;
}
#header.active .menu li:last-child a {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
  padding: 15px;
  text-decoration: none;
  min-width: 115px;
  display: inline-block;
  -webkit-text-fill-color: unset;
  transition: none;
}
.welcomecontent-left {
  /* float: left;
  width: 50%; */
}
.welcomecontent-right {
  /* float: right;
  width: 48%; */
  /* padding-left: 10%; */
  text-align: right;
}

.welcomecontent-right .content-boxs {
  display: inline-block;
  /* width: 252px; */
  margin: 0 15px 15px 0;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 20%, rgba(32, 172, 228, 1) 180%);
  padding: 15px 20px;
  vertical-align: top;
  min-height: 245px;
  text-align: left;
  position: relative;
  height: 255px;
  border-radius: 10px;
}

.welcomecontent-right .content-boxs.blue-shade {
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 0%, rgba(32, 172, 228, 1) 0%);
}
.welcomecontent-right .content-boxs:nth-child(2),
.welcomecontent-right .content-boxs:nth-child(4) {
  margin-right: 0;
}
.welcomecontent-right .content-boxs p {
  color: #fff;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 28px;
}
.welcomecontent-right .content-boxs a.grey-btn {
  position: absolute;
  left: 15px;
  bottom: 15px;
}
.welcomecontent-right .content-boxs.contact-box {
  padding: 0;
  background: #e8eae4;
  width: 252px;
  height: 255px;
  box-shadow: 8px 6px 20px 5px rgb(0 0 0 / 40%), inset 0px -19px 20px -14px rgb(0 0 0 / 50%);
}
.welcomecontent-right .content-boxs.contact-box .box-head {
  padding: 12px 15px 10px 15px;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 20%, rgba(32, 172, 228, 1) 180%);
}
.welcomecontent-right .content-boxs.contact-box .box-head h2 {
  color: #fff;
  letter-spacing: 2px;
}
.welcomecontent-right .content-boxs.contact-box form {
  padding: 20px 15px;
}
.welcomecontent-right .content-boxs.contact-box form .input-wrap {
  border: 5px solid transparent;
  border-radius: 6px;
  background-image: linear-gradient(white, white), linear-gradient(to top, #ffffff, #ababab);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-bottom: 10px;
}
.welcomecontent-right .content-boxs.contact-box form input {
  background: #e8eae4;
  display: block;
  width: 100%;
  padding: 7px 10px;
  box-sizing: border-box;
  font-size: 12px;
  border: none;
  color: #808080;
}
.welcomecontent-right .content-boxs.contact-box form button {
  border: none;
  padding: 10px 15px;
  margin-top: 0px;
  cursor: pointer;
}
#currentproject {
  padding: 40px 0 10px;
}
#currentproject h2 {
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.project-section .owl-carousel .owl-nav .owl-prev,
.download-section .owl-carousel .owl-nav .owl-prev {
  background: url(../images/prev-g.png) no-repeat !important;
  background-size: 100% auto !important;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  opacity: 0.8;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translate(0, -50%);
  outline: none !important;
}
.project-section .owl-carousel .owl-nav .owl-next,
.download-section .owl-carousel .owl-nav .owl-next {
  background: url(../images/next-g.png) no-repeat !important;
  background-size: 100% auto !important;
  width: 30px;
  height: 30px;
  opacity: 0.8;
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translate(0, -50%);
  outline: none !important;
}
.owl-carousel .owl-nav span {
  font-size: 0;
}
.project-section .owl-carousel .owl-nav .owl-prev:hover,
.project-section .owl-carousel .owl-nav .owl-next:hover,
.download-section .owl-carousel .owl-nav .owl-prev:hover,
.download-section .owl-carousel .owl-nav .owl-next:hover {
  opacity: 1;
}
.project-section .owl-carousel .owl-nav.disabled,
.download-section .owl-carousel .owl-nav.disabled {
  display: block;
}
.project-section .product-image,
.download-section .product-image {
  height: 225px;
  overflow: hidden;
}
.project-section .product-image img,
.download-section .product-image img {
  display: block;
  width: 100%;
  height: auto;
}
.project-section .product-content,
.download-section .product-content {
  padding: 15px;
  position: relative;
}
.project-section .product-content h4,
.download-section .product-content h4 {
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.project-section .product-content p,
.download-section .product-content p {
  font-size: 14px;
  line-height: 20px;
  color: #616060;
  margin: 0;
}
.project-section .products,
.download-section .products {
  /* border: 1px solid #d2d2d2; */
  border-radius: 5px;
  /* height: 355px; */
}
.project-section .project-logo,
.download-section .project-logo {
  border: 1px solid #d2d2d2;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  width: 45px;
  height: 40px;
  position: absolute;
  right: 15px;
  top: -40px;
  display: flex;
}
.project-section .project-logo img,
.download-section .project-logo img {
  display: block;
  width: 100%;
  height: auto;
  align-self: center;
}
#footer-btn {
  padding: 30px 0;
}
#footer-btn ul {
  text-align: right;
}
#footer-btn li {
  display: inline-block;
  margin-left: 15px;
}
#footer-btn li a {
  text-decoration: none;
  font-size: 24px;
  min-width: 150px;
}

#footer-btn-video {
  padding: 30px 0;
  padding-left: 50px;
  margin-bottom: 30px;
}
#footer-btn-video ul {
  text-align: center;
}
#footer-btn-video p {
  text-align: center;
  font-size: 19px;
}
#footer-btn-video li {
  display: inline-block;
  margin-left: 15px;
}
#footer-btn-video li a {
  text-decoration: none;
  font-size: 24px;
  min-width: 150px;
}
#inside-wrap {
  background: url(../images/inside-bg.png) no-repeat;
  background-size: cover;
  background-position: right center;
  min-height: 100vh;
}
#inside-wrap .header-wrap .header-left .logo a {
  display: block;
  background: url(../images/logo-white.png) no-repeat;
  background-size: 100% auto;
  height: 80px;
  margin: 0 auto;
  width: 240px;
}

#inside-wrap .header-wrap .header-left-signup .logo a {
  display: block;
  background: url(../images/logo-white.png) no-repeat;
  background-size: 100% auto;
  height: 80px;
  margin: 0 auto;
  width: 240px;
}
#inside-wrap #header2 {
  margin: 0;
  padding: 30px 0;
}

#inside-wrap #header23 {
  margin: 0;
  padding: 0px 0;
}
#inside-wrap h1 {
  color: #fff;
  -webkit-text-fill-color: unset;
}
.menu-second {
  margin-top: 25px;
}
.menu-second .grey-btn {
  padding: 15px 15px 13px;
  min-width: 100px;
}
#register-section {
  width: 45%;
}
#register-section h1 {
  font-size: 42px;
  margin-bottom: 30px;
}

#register-section-steps {
  width: 100%;
}
#register-section-steps h1 {
  font-size: 42px;
  margin-bottom: 30px;
}
#register-section-stepsone {
  width: 100%;
}
#register-section-stepsone h1 {
  font-size: 42px;
  margin-bottom: 30px;
}
.register-boxwrap {
  padding: 0;
  border-radius: 5px;
  background: #e8eae4;
  box-shadow: 8px 6px 20px 5px rgb(0 0 0 / 40%), inset 0px -19px 20px -14px rgb(0 0 0 / 50%);
}
.register-boxwrap form .input-wrap {
  border: 5px solid transparent;
  border-radius: 6px;
  background-image: linear-gradient(white, white), linear-gradient(to top, #ffffff, #ababab);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-bottom: 15px;
}
.register-boxwrap form input,
.register-boxwrap form select,
.register-boxwrap form textarea {
  background: #e8eae4;
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 13px;
  border: none;
  color: #808080;
}
.register-boxwrap form button {
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
}
.register-boxwrap ul {
  padding: 10px 10px 0 10px;
  border-bottom: 2px solid #afafa3;
  text-align: center;
}
.register-boxwrap li {
  display: inline-block;
}
.register-boxwrap li a {
  display: block;
  text-decoration: none;
  color: #74b531;
  padding: 13px;
  text-align: center;
  border: 2px solid transparent;
}
.register-boxwrap li a.active {
  border: 2px solid #afafa3;
  border-bottom: 2px solid transparent;
  top: 2px;
  position: relative;
  background: #e8eae4;
}
.register-boxwrap .box-head {
  padding: 12px 15px 10px 15px;
  background: rgb(108, 188, 135);
  background: linear-gradient(90deg, rgba(108, 188, 135, 1) 20%, rgba(32, 172, 228, 1) 180%);
}
.register-boxwrap .box-head h2 {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 20px;
}
.register-box {
  padding: 30px 20px;
  display: none;
}
.download-heading {
  font-size: 30px !important;
  text-transform: capitalize !important;
  margin-bottom: 50px !important;
}
.download-section {
  padding: 0 100px;
  margin-bottom: 50px;
}
.download-btn {
  padding: 0 100px;
}
#register-section.project-register {
  width: 35%;
}
#register-section-steps.project-register {
  padding-top: 13px;
  /* padding-left: 99px; */

  width: 100%;
}
#register-section-stepsone.project-register {
  padding-top: 8px;
  /* padding-left: 129px; */

  width: 100%;
}
.download-btn .default-btn {
  padding: 20px;
  font-size: 14px;
  min-width: 200px;
  margin-bottom: 25px;
}
.custom-form {
  padding: 30px 75px;
}
.custom-form label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  display: block;
  font-style: normal;
  word-break: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif !important;
  color: rgb(31, 31, 31);
}
.custom-form input {
  box-sizing: border-box !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: inherit !important;
  width: 100% !important;
  max-width: 100% !important;
  border: 1px solid rgb(209, 209, 209) !important;
  color: rgb(31, 31, 31) !important;
  box-shadow: rgb(0 0 0 / 4%) 1px 1px 3px !important;
  transition: border-radius 200ms ease 0s !important;
  height: 36px !important;
  padding: 8px 12px !important;
  border-radius: 18px !important;
  background: #fff !important;
}
.custom-form .input-wrapper {
  margin-bottom: 15px;
}
.custom-form h4 {
  font-style: normal;
  font-size: 26px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 16px;
  color: rgb(31, 31, 31) !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}
.custom-form p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 15px;
  display: block;
  font-style: normal;
  word-break: break-word;
  color: rgb(31, 31, 31) !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}
.custom-form .radio-wrap {
  margin-bottom: 20px;
}
.custom-form .radio-wrap input {
  display: inline-block !important;
  margin-right: 7px !important;
  vertical-align: middle !important;
  box-shadow: rgb(0 0 0 / 4%) 1px 1px 3px;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
}
.custom-form .radio-wrap label {
  display: inline-block !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  font-size: 14px !important;
  margin: 0 !important;
}
.custom-form .radio-wrap span {
  display: block;
  margin-bottom: 10px;
}
.custom-form button {
  box-shadow: rgb(0 0 0 / 4%) 1px 1px 3px;
  transition: border-radius 200ms ease 0s;
  height: unset;
  padding: 8px 12px;
  margin-top: 24px;
  min-height: 48px;
  border-radius: 24px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  background: none !important;
  background-color: rgb(16, 232, 204) !important;
  border: 0px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-family: inherit;
  font-style: normal;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  outline: none;
  margin-top: 20px !important;
}
.custom-form button:hover {
  background-color: rgb(3, 219, 191) !important;
}
/* .custom-form{}
.custom-form{}
 */

.cl {
  clear: both;
  font-size: 0;
  height: 0;
}
.clearfix:after {
  clear: both;
  content: " ";
  display: block;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

/* Alignments */
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.top {
  vertical-align: top;
}
.middle {
  vertical-align: middle;
}

/* Responsive */
@media (max-width: 1400px) {
  .header-wrap .top-contactdetail li:last-child {
    margin-left: 35px;
  }
  .header-wrap .top-contactdetail li a {
    font-size: 16px;
  }
  .header-wrap .menu li {
    margin-left: 75px;
  }
  .header-wrap .menu li a {
    font-size: 22px;
  }
  h1 {
    font-size: 26px;
  }
  p {
    line-height: 26px;
    margin-bottom: 25px;
    font-size: 14px;
  }
  /* .welcomecontent-right {
    width: 45%;
    padding-left: 5%;
  } */

  .welcomecontent-right .content-boxs {
    margin: 0 10px 10px 0;
    padding: 15px 20px;
    min-height: 206px;
  }
  .welcomecontent-right .content-boxs p {
    margin-bottom: 0;
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 24px;
  }
  .grey-btn {
    padding: 8px 10px 6px;
    font-size: 12px;
  }
  .welcomecontent-right .content-boxs.contact-box form {
    padding: 15px 10px;
  }
  .welcomecontent-right .content-boxs.contact-box form input {
    padding: 5px 10px;
    font-size: 10px;
  }
  .welcomecontent-right .content-boxs.contact-box form button {
    margin-top: 0;
  }
  #register-section {
    width: 48%;
  }
  #register-section h1 {
    font-size: 35px;
    margin-bottom: 25px;
  }

  #register-section-steps {
    width: 48%;
  }
  #register-section-steps h1 {
    font-size: 35px;
    margin-bottom: 5px;
  }

  #register-section-stepsone {
    width: 48%;
  }
  #register-section-stepsone h1 {
    font-size: 35px;
    margin-bottom: 5px;
  }

  .register-boxwrap ul {
    padding: 5px 5px 0 5px;
    border-bottom: 1px solid #afafa3;
  }
  .register-boxwrap li a {
    display: block;
    padding: 12px;
    border: 1px solid transparent;
  }
  .register-boxwrap li a.active {
    border: 1px solid #afafa3;
    border-bottom: 1px solid transparent;
    top: 1px;
    position: relative;
  }
  .register-box {
    padding: 25px 20px;
    width: 65%;
  }
  .register-boxwrap form .input-wrap {
    margin-bottom: 13px;
  }
  .register-boxwrap form input,
  .register-boxwrap form select {
    padding: 7px;
    font-size: 12px;
  }
  .register-boxwrap form button {
    margin-top: 5px;
  }
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.cardbackOpportunities {
  background: rgb(17, 196, 74);
  background: linear-gradient(90deg, rgba(17, 196, 74, 1) 0%, rgba(101, 170, 203, 1) 71%);
  width: 100%;
  height: 130px;
  text-align: center;
}

.avtarclass {
  background: rgb(17, 196, 74);
  background: linear-gradient(90deg, rgba(17, 196, 74, 1) 0%, rgba(101, 170, 203, 1) 71%);
}

.ant-card-body {
  padding: 0px;
}

.display-text-group {
  padding: 13px;
  color: "gray";
}
/* headings and paragraphs */
.p-14 {
  font-size: 14px !important;
}
.p-16 {
  font-size: 16px !important;
}
.sup-p {
  top: -3px;
}
/* colors */
.text-grey {
  color: #616060 !important;
}
.text-black {
  color: #000 !important;
}
.text-white {
  color: #fff !important;
}
.bg-red {
  background-color: red;
}

/* padding and margins height and width*/
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-1-px {
  margin-bottom: 10px !important;
}
.home-para-pad {
  padding-right: 6rem;
}

.ant-layout-header {
  height: 127px;
  background: #fff;
}
.ant-menu-horizontal {
  border-bottom: 0px;
}
.ant-menu-title {
  font-size: 22px;
  border-bottom: none;
}
.ant-menu-overflow {
  display: block;
}

/* fonts */
.font-16 {
  font-size: 16px !important;
}

/* .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
  font-size: 22px;
  color: linear-gradient(90deg,#6cbc87 10%,#20ace4) !important;
  border-bottom: none;
} */

/* working css start */
.ant-menu-horizontal > .ant-menu-item a:hover {
  font-size: 22px;
  background: -webkit-linear-gradient(90deg, #6cbc87 10%, #20ace4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Conv_Myriad Pro Semibold";
}

.ant-menu-title-content {
  font-size: 22px;
  background: -webkit-linear-gradient(90deg, #6cbc87 10%, #20ace4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Conv_Myriad Pro Semibold";
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: none;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none;
}

/* working css ends */
/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color: linear-gradient(90deg,#6cbc87 10%,#20ace4) !important ;
  text-decoration: none;
} */
/* .ant-menu-item, .ant-menu-submenu-title{
  font-size: 22px;
  background: -webkit-linear-gradient(90deg,#6cbc87 10%,#20ace4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ant-menu-item:hover, .ant-menu-submenu-title:hover{
  font-size: 22px;
  background: -webkit-linear-gradient(90deg,#6cbc87 10%,#20ace4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
} */
/* widths and heights */

/* display */
.d-flex {
  display: flex;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.justify-content-center {
  justify-content: center !important;
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}

/* custom antd changes */
.ant-result-title {
  color: #616060 !important;
}
/* .ant-card-bordered {
  border: 0;
} */
/* font weights and sizes */
.font-weight-bold {
  font-weight: 700;
}
.welcomedemec {
  margin-bottom: 6px;
  font-size: 48px;
}
.homedemec {
  margin-bottom: 20px;
  font-size: 24px;
}
.sup9 {
  height: 10px;
  margin-top: 34px;
  color: #6cbc87;
  font-size: 24px;
  font-weight: 700;
}

/* responsive video */
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* responsive contact  */
.custom-contact-form {
  width: 235px;
  margin-left: 13px;
  border-radius: 10px;
  min-height: 245px;
  height: 255px;
}
/* images */
.img-fluid {
  max-width: 100%;
  height: auto;
}
.userprofileavatar {
  margin-right: 40px;
}

/* media queries */

@media only screen and (max-width: 375px) {
  .homedemec {
    font-size: 17px !important;
  }
  .sup9 {
    margin-top: 22px !important;

    font-size: 15px !important;
  }
  #footer-btn-video {
    padding-left: 0px !important;
  }
  #footer-btn-video p {
    font-size: 17px !important;
  }

  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
  }

  .userprofileavatar {
    margin-right: 0px !important;
  }
  /* .header-wrap .header-left {
    float: left;
    width: 150px;
margin: 0 60px;
  } */

  .menu .navbar-header {
    display: none;
    position: absolute;
    left: 0;
    top: -27px;
    z-index: 99;
  }

  /* mob respon */

  .menu .navbar-header {
    display: block;
    position: absolute;
    left: 43px;
    top: 60px;
    z-index: 99;
  }

  .header-wrap {
    padding: 10px 0;
  }
  .header-wrap .header-left {
    width: 150px;
    float: none;
    margin: 0 60px;
  }
  .header-wrap .header-left .logo a {
    height: 35px;
    float: right;
    text-align: center;
    margin-left: 270px;
    width: 100%;
  }
  .header-wrap .header-right {
    width: 100%;
    float: none;
    text-align: right;
    margin-top: 60px;
  }
  .header-wrap .top-contactdetail {
    padding-bottom: 0;
  }
  .header-wrap .top-contactdetail li:last-child {
    margin-left: 0;
    display: block;
  }

  .navbar-header .collapse-button {
    background: none;
    border: none;
    height: 25px;
    padding-top: 0;
    transition: all 450ms ease-out 0s;
    width: auto;
    vertical-align: middle;
    outline: none;
  }
  .navbar-header .collapse-button span {
    display: block;
    width: 28px;
    height: 2px;
    margin-bottom: 7px;
    background: rgb(108, 188, 135);
    background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  }
  .navbar-header .collapse-button span:nth-child(2) {
    width: 18px;
  }
  .navbar-header em {
    display: inline-block;
    font-style: normal;
    margin: 3px 0 0 5px;
    vertical-align: middle;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .menu ul {
    display: none;
    text-align: left;
    padding: 10px 0;
  }
  .header-wrap .menu li {
    display: block;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
  .header-wrap .menu li a {
    font-size: 12px;
    padding: 8px 0;
  }
  #header.active .menu {
    display: none !important;
  }
  .welcomecontent-left {
    width: 100%;
    float: none;
    padding-bottom: 20px;
  }
  .welcomecontent-right {
    width: 100%;
    padding-left: 0;
    float: none;
    padding-bottom: 20px;
  }
  p {
    line-height: 18px;
    font-size: 11px;
  }

  /*  */

  /* .welcomecontent-right .content-boxs {
    margin: 0 2px 5px 0;
    padding: 10px 10px;
    min-height: 172px;
    width: 140px;
    display: block;
    width: 49%;
    box-sizing: border-box;
    min-height: inherit;
  }
  .welcomecontent-right .content-boxs a.grey-btn {
		position: static;
		margin-top: 10px;
	}
  .welcomecontent-right .content-boxs.contact-box {
    width: 48%;
    padding: 0;
    min-height: inherit;
  } */

  /*  */
  #currentproject {
    padding: 0 0 20px 0;
  }
  .project-section .product-image,
  .download-section .product-image {
    height: 205px;
    overflow: hidden;
  }
  .owl-dots {
    display: none;
  }
  #footer-btn {
    padding: 0 0 15px 0;
  }
  #footer-btn ul {
    text-align: center;
  }
  #footer-btn li {
    display: inline-block;
    margin-left: 10px;
  }
  #footer-btn li:first-child {
    margin: 0;
  }
  #footer-btn li a {
    text-decoration: none;
    font-size: 12px;
    min-width: 90px;
  }
  #inside-wrap #header2 {
    margin: 0;
    padding: 10px 0 0 0;
  }
  #inside-wrap .header-wrap .header-left .logo a {
    width: 100%;
    height: 40px;
  }
  .menu-second {
    margin-top: 0;
  }
  .menu-second .grey-btn {
    padding: 10px 10px 9px;
    min-width: 100px;
    font-size: 11px;
    display: block;
  }
  #register-section {
    width: 100%;
  }
  #register-section h1 {
    font-size: 18px;
    margin: 10px 0 15px;
  }
  .register-boxwrap li {
    display: block;
  }
  .register-boxwrap li a {
    font-size: 12px;
    text-align: left;
  }
  .register-boxwrap li a.active {
    border: none;
    top: 0;
    background: #74b531;
    color: #fff;
  }
  #register-section.project-register {
    width: 100%;
  }
  .register-boxwrap .box-head h2 {
    font-size: 15px;
  }
  .download-btn .default-btn {
    padding: 10px;
    font-size: 12px;
    min-width: 200px;
    margin-bottom: 0;
    display: block;
  }

  .menu .navbar-header {
    display: none;
    position: absolute;
    left: 0;
    top: 60px !important;
    z-index: 99;
    width: 80px;
    display: block !important;
  }

  .ant-divider.ant-divider-vertical {
    display: none;
  }
  .userprofdata {
    text-align: center !important;
  }

  .anchor-back {
    position: relative;
    top: -90px;
    left: 10px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 425px) {
  .btn-responsive-milestone {
    color: white;
    font-size: 16px;
    height: 50px;
    position: absolute;
    top: -171px;
    left: -34px;
  }

  .anchor-back {
    position: relative;
    top: -90px;
    left: 20px;
    font-size: 16px;
  }

  .home-para-pad {
    padding-right: 0px !important;
  }
  .homedemec {
    font-size: 21px;
  }
  .sup9 {
    margin-top: 26px;

    font-size: 18px;
  }
  #footer-btn-video {
    padding-left: 0px;
  }
  #footer-btn-video p {
    font-size: 17px;
  }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
  }

  /* .header-wrap .header-left {
    float: left;
    width: 150px;
margin: 0 90px;
  } */

  .menu .navbar-header {
    display: none;
    position: absolute;
    left: 0;
    top: -27px;
    z-index: 99;
  }
  /* mob respon  */

  .menu .navbar-header {
    display: block;
    position: absolute;
    left: 43px;
    top: 60px;
    z-index: 99;
  }

  .header-wrap {
    padding: 10px 0;
  }
  .header-wrap .header-left {
    width: 110px;
    float: none;
    margin: 0 auto 15px;
  }
  .header-wrap .header-left .logo a {
    height: 35px;
    float: right;
    text-align: center;
    margin-left: 270px;
    width: 100%;
  }
  .header-wrap .header-right {
    width: 100%;
    float: none;
    text-align: right;
    margin-top: 60px;
  }
  .header-wrap .top-contactdetail {
    padding-bottom: 0;
  }
  .header-wrap .top-contactdetail li:last-child {
    margin-left: 0;
    display: block;
  }

  .navbar-header .collapse-button {
    background: none;
    border: none;
    height: 25px;
    padding-top: 0;
    transition: all 450ms ease-out 0s;
    width: auto;
    vertical-align: middle;
    outline: none;
  }
  .navbar-header .collapse-button span {
    display: block;
    width: 28px;
    height: 2px;
    margin-bottom: 7px;
    background: rgb(108, 188, 135);
    background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  }
  .navbar-header .collapse-button span:nth-child(2) {
    width: 18px;
  }
  .navbar-header em {
    display: inline-block;
    font-style: normal;
    margin: 3px 0 0 5px;
    vertical-align: middle;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .menu ul {
    display: none;
    text-align: left;
    padding: 30px 0;
  }
  .header-wrap .menu li {
    display: block;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
  .header-wrap .menu li a {
    font-size: 12px;
    padding: 8px 0;
  }
  #header.active .menu {
    display: none !important;
  }
  .welcomecontent-left {
    width: 100%;
    float: none;
    padding-bottom: 20px;
  }
  .welcomecontent-right {
    width: 100%;
    padding-left: 0;
    float: none;
    padding-bottom: 20px;
  }
  p {
    line-height: 18px;
    font-size: 11px;
  }

  /*  */
  /* 
.welcomecontent-right .content-boxs {
  margin: 0 2px 5px 0;
  padding: 10px 10px;
  min-height: 172px;
  width: 140px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  min-height: inherit;
}
.welcomecontent-right .content-boxs a.grey-btn {
  position: static;
  margin-top: 10px;
}
.welcomecontent-right .content-boxs.contact-box {
  width: 48%;
  padding: 0;
  min-height: inherit;
} */

  /*  */
  #currentproject {
    padding: 0 0 20px 0;
  }
  .project-section .product-image,
  .download-section .product-image {
    height: 205px;
    overflow: hidden;
  }
  .owl-dots {
    display: none;
  }
  #footer-btn {
    padding: 0 0 15px 0;
  }
  #footer-btn ul {
    text-align: center;
  }
  #footer-btn li {
    display: inline-block;
    margin-left: 10px;
  }
  #footer-btn li:first-child {
    margin: 0;
  }
  #footer-btn li a {
    text-decoration: none;
    font-size: 12px;
    min-width: 90px;
  }
  #inside-wrap #header2 {
    margin: 0;
    padding: 10px 0 0 0;
  }
  #inside-wrap .header-wrap .header-left .logo a {
    width: 100%;
    height: 40px;
  }
  .menu-second {
    margin-top: 0;
  }
  .menu-second .grey-btn {
    padding: 10px 10px 9px;
    min-width: 100px;
    font-size: 11px;
    display: block;
  }
  #register-section {
    width: 100%;
  }
  #register-section h1 {
    font-size: 18px;
    margin: 10px 0 15px;
  }
  .register-boxwrap li {
    display: block;
  }
  .register-boxwrap li a {
    font-size: 12px;
    text-align: left;
  }
  .register-boxwrap li a.active {
    border: none;
    top: 0;
    background: #74b531;
    color: #fff;
  }
  #register-section.project-register {
    width: 100%;
  }
  .register-boxwrap .box-head h2 {
    font-size: 15px;
  }
  .download-btn .default-btn {
    padding: 10px;
    font-size: 12px;
    min-width: 200px;
    margin-bottom: 0;
    display: block;
  }

  .menu .navbar-header {
    display: none;
    position: absolute;
    left: 0;
    top: 60px !important;
    z-index: 99;
    width: 80px;
    display: block !important;
    margin-top: 22px;
  }
  .userprofileavatar {
    margin-right: 0px;
  }
  .ant-divider.ant-divider-vertical {
    display: none;
  }
  .userprofdata {
    text-align: center;
  }
}
@media only screen and (max-width: 320px) {
  .btn-responsive-milestone {
    color: white;
    font-size: 16px;
    height: 50px;
    position: absolute;
    top: -122px;
    left: -7px;
  }
  .welcomedemec {
    font-size: 22px !important;
    margin-bottom: 1px;
  }
  .custom-contact-form {
    width: 190px !important;
  }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: -15px;
    width: auto;
    height: auto;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .profile-btn {
    height: 27px;
    font-size: 8px;
    padding: 4px !important;
  }

  .buttonColor {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    background: rgb(108, 188, 135);
    background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
    border-radius: 100px;
    padding: 15px;
    text-decoration: none;
    min-width: 50px;
    display: inline-block;
  }

  .cardStyle {
    padding: 25px;
    border: 3px solid #6cbc87 !important;
    border-radius: 30px;
    margin-top: 30px;
    width: auto;
}

  .welcomedemec {
    font-size: 25px;
    margin-bottom: 3px;
  }
  .custom-contact-form {
    width: 263px;
    margin-left: 0;
  }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: -20px;
    width: auto;
    height: auto;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .welcomedemec {
    font-size: 32px;
    margin-bottom: 6px;
  }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
  }
  .custom-contact-form {
    width: 312px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .btn-responsive-milestone {
    color: white;
    font-size: 16px;
    height: 50px;
    position: absolute;
    top: -122px;
    left: 24px;
  }
  #footer-btn-video {
    padding-top: 0px !important;
  }
  .home-para-pad {
    padding-right: 0px !important;
  }
  .custom-contact-form {
    width: 300px;
  }
  .header-wrap {
    display: flex !important;
  }
  .header-wrap .header-left {
    margin-top: 30px;
  }
  .collapse-nav {
    margin-top: 15px;
  }

  .menu .navbar-header {
    display: block;
    position: absolute;
    left: 43px;
    top: 60px;
    z-index: 99;
  }

  .header-wrap {
    padding: 10px 0;
  }
  .header-wrap .header-left {
    width: 110px;
    float: none;
    margin: 0 auto 15px;
  }
  .header-wrap .header-left .logo a {
    height: 35px;
    /* float: right; */
    text-align: center;
    margin-left: 270px;
    width: 100%;
  }
  .header-wrap .header-right {
    width: 100%;
    float: none;
    text-align: right;
    margin-top: 60px;
  }
  .header-wrap .top-contactdetail {
    padding-bottom: 0;
  }
  .header-wrap .top-contactdetail li:last-child {
    margin-left: 0;
    display: block;
  }

  .navbar-header .collapse-button {
    background: none;
    border: none;
    height: 25px;
    padding-top: 0;
    transition: all 450ms ease-out 0s;
    width: auto;
    vertical-align: middle;
    outline: none;
  }
  .navbar-header .collapse-button span {
    display: block;
    width: 28px;
    height: 2px;
    margin-bottom: 7px;
    background: rgb(108, 188, 135);
    background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  }
  .navbar-header .collapse-button span:nth-child(2) {
    width: 18px;
  }
  .navbar-header em {
    display: inline-block;
    font-style: normal;
    margin: 3px 0 0 5px;
    vertical-align: middle;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .menu ul {
    display: none;
    text-align: left;
    padding: 10px 0;
  }
  .header-wrap .menu li {
    display: block;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
  .header-wrap .menu li a {
    font-size: 12px;
    padding: 8px 0;
  }
  #header.active .menu {
    display: none !important;
  }
  .welcomecontent-left {
    width: 100%;
    float: none;
    padding-bottom: 20px;
  }
  .welcomecontent-right {
    width: 100%;
    padding-left: 0;
    float: none;
    padding-bottom: 20px;
  }
  p {
    line-height: 18px;
    font-size: 11px;
  }
  #footer-btn-video p {
    font-size: 13px !important;
    padding-top: 10px !important;
  }

  /*  */

  /* .welcomecontent-right .content-boxs {
    margin: 0 2px 5px 0;
    padding: 10px 10px;
    min-height: 172px;
    width: 140px;
    display: block;
    width: 96%;
    box-sizing: border-box;
    min-height: inherit;
  }
  .welcomecontent-right .content-boxs a.grey-btn {
		position: static;
		margin-top: 10px;
	}
  .welcomecontent-right .content-boxs.contact-box {
    width: 48%;
    padding: 0;
    min-height: inherit;
  } */

  /*  */
  #currentproject {
    padding: 0 0 20px 0;
  }
  .project-section .product-image,
  .download-section .product-image {
    height: 205px;
    overflow: hidden;
  }
  .owl-dots {
    display: none;
  }
  #footer-btn {
    padding: 0 0 15px 0;
  }
  #footer-btn ul {
    text-align: center;
  }
  #footer-btn li {
    display: inline-block;
    margin-left: 10px;
  }
  #footer-btn li:first-child {
    margin: 0;
  }
  #footer-btn li a {
    text-decoration: none;
    font-size: 12px;
    min-width: 90px;
  }
  #inside-wrap #header2 {
    margin: 0;
    padding: 10px 0 0 0;
  }
  #inside-wrap .header-wrap .header-left .logo a {
    width: 100%;
    height: 40px;
  }
  .menu-second {
    margin-top: 0;
  }
  .menu-second .grey-btn {
    padding: 10px 10px 9px;
    min-width: 100px;
    font-size: 11px;
    display: block;
  }
  #register-section {
    width: 100%;
  }
  #register-section h1 {
    font-size: 18px;
    margin: 10px 0 15px;
  }
  .register-boxwrap li {
    display: block;
  }
  .register-boxwrap li a {
    font-size: 12px;
    text-align: left;
  }
  .register-boxwrap li a.active {
    border: none;
    top: 0;
    background: #74b531;
    color: #fff;
  }
  #register-section.project-register {
    width: 100%;
  }
  .register-boxwrap .box-head h2 {
    font-size: 15px;
  }
  .download-btn .default-btn {
    padding: 10px;
    font-size: 12px;
    min-width: 200px;
    margin-bottom: 0;
    display: block;
  }

  .menu .navbar-header {
    display: none;
    position: absolute;
    left: 0;
    top: 60px !important;
    z-index: 99;
    width: 80px;
    display: block !important;
  }
  .ant-divider.ant-divider-vertical {
    display: none;
  }

  .userprofdata {
    text-align: center;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #footer-btn-video {
    padding-top: 65px !important;
  }

  .welcomecontent-right .content-boxs {
    width: 183px !important;
  }
  .custom-contact-form {
    width: 185px !important;
    margin-left: 0 !important;
  }
  .investleftcard {
    width: 180px !important;
  }
  .header-wrap {
    display: flex;
  }
  .header-wrap .header-left {
    margin-top: 2px;
  }
  .header-wrap .menu li {
    left: -16px;
    margin-left: 35px !important;
  }
  .header-wrap .menu li a {
    font-size: 15px !important;
  }

  /* hide hamsburger */
  .navbar-header {
    display: none !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .welcomecontent-right .content-boxs {
    display: inline-block;
    width: 237px !important;
    margin: 0 15px 15px 0;

    padding: 15px 20px;
    vertical-align: top;
    min-height: 245px;
    text-align: left;
    position: relative;
    height: 255px;
    border-radius: 10px;
  }
  .custom-contact-form {
    width: 215px !important;
  }
  .homeContactCard {
    width: 18rem;
  }
  #footer-btn-video {
    padding-top: 130px !important;
  }
  .welcomecontent-right .content-boxs.blue-shade {
    margin: 0 30px 0 0 !important;
  }
  .investleftcard {
    width: 280px !important;
  }
  .header-wrap {
    display: flex;
  }
  .header-wrap .header-left {
    margin-top: 5px;
  }

  /* hide hamsburger */
  .navbar-header {
    display: none;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 2560px) {
  .btn-responsive-milestone {
    color: white;
    font-size: 20px;
    margin-top: 20px;
    height: 55px;
    position: absolute;
    top: -170px;
    left: -16px;
  }

  .anchor-back {
    position: relative;
    top: -90px;
    left: 135px;
    font-size: 16px;
  }

  .welcomecontent-right .content-boxs {
    width: 252px !important;
  }
  #footer-btn-video {
    padding-top: 175px !important;
  }
  .custom-contact-form {
    width: 235px !important;
  }
  .investleftcard {
    width: 360px !important ;
  }
  .header-wrap {
    display: flex;
  }
  .header-wrap .header-left {
    margin-top: 10px;
  }

  /* hide hamsburger */
  .navbar-header {
    display: none;
  }
}

/* header hamza responsivness */

/* .menu .navbar-header {
  display: none;
  position: absolute;
  left: 0;
  top: -27px;
  z-index: 99;
} */
/* 
@media (max-width: 640px) {
  .collapse-nav {
    margin-top: 15px;
  }

  .menu .navbar-header {
    display: block;
    position: absolute;
    left: 43px;
    top: 60px;
    z-index: 99;
  }

  .header-wrap {
    padding: 10px 0;
  }
  .header-wrap .header-left {
    width: 110px;
    float: none;
    margin: 0 auto 15px;
  }
  .header-wrap .header-left .logo a {
    height: 35px;
  }
  .header-wrap .header-right {
    width: 100%;
    float: none;
    text-align: right;
  }
  .header-wrap .top-contactdetail {
    padding-bottom: 0;
  }
  .header-wrap .top-contactdetail li:last-child {
    margin-left: 0;
    display: block;
  }

  .navbar-header .collapse-button {
    background: none;
    border: none;
    height: 25px;
    padding-top: 0;
    transition: all 450ms ease-out 0s;
    width: auto;
    vertical-align: middle;
    outline: none;
  }
  .navbar-header .collapse-button span {
    display: block;
    width: 28px;
    height: 2px;
    margin-bottom: 7px;
    background: rgb(108, 188, 135);
    background: linear-gradient(90deg, rgba(108, 188, 135, 1) 10%, rgba(32, 172, 228, 1) 100%);
  }
  .navbar-header .collapse-button span:nth-child(2) {
    width: 18px;
  }
  .navbar-header em {
    display: inline-block;
    font-style: normal;
    margin: 3px 0 0 5px;
    vertical-align: middle;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .menu ul {
    display: none;
    text-align: left;
    padding: 10px 0;
  }
  .header-wrap .menu li {
    display: block;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
  .header-wrap .menu li a {
    font-size: 12px;
    padding: 8px 0;
  }
  #header.active .menu {
    display: none !important;
  }
  .welcomecontent-left {
    width: 100%;
    float: none;
    padding-bottom: 20px;
  }
  .welcomecontent-right {
    width: 100%;
    padding-left: 0;
    float: none;
    padding-bottom: 20px;
  }
  p {
    line-height: 18px;
    font-size: 11px;
  }




  .welcomecontent-right .content-boxs {
    margin: 0 2px 5px 0;
    padding: 10px 10px;
    min-height: 172px;
    width: 140px;
    display: block;
    width: 49%;
    box-sizing: border-box;
    min-height: inherit;
  }
  .welcomecontent-right .content-boxs a.grey-btn {
		position: static;
		margin-top: 10px;
	}
  .welcomecontent-right .content-boxs.contact-box {
    width: 48%;
    padding: 0;
    min-height: inherit;
  }



  
  #currentproject {
    padding: 0 0 20px 0;
  }
  .project-section .product-image,
  .download-section .product-image {
    height: 205px;
    overflow: hidden;
  }
  .owl-dots {
    display: none;
  }
  #footer-btn {
    padding: 0 0 15px 0;
  }
  #footer-btn ul {
    text-align: center;
  }
  #footer-btn li {
    display: inline-block;
    margin-left: 10px;
  }
  #footer-btn li:first-child {
    margin: 0;
  }
  #footer-btn li a {
    text-decoration: none;
    font-size: 12px;
    min-width: 90px;
  }
  #inside-wrap #header2 {
    margin: 0;
    padding: 10px 0 0 0;
  }
  #inside-wrap .header-wrap .header-left .logo a {
    width: 100%;
    height: 40px;
  }
  .menu-second {
    margin-top: 0;
  }
  .menu-second .grey-btn {
    padding: 10px 10px 9px;
    min-width: 100px;
    font-size: 11px;
    display: block;
  }
  #register-section {
    width: 100%;
  }
  #register-section h1 {
    font-size: 18px;
    margin: 10px 0 15px;
  }
  .register-boxwrap li {
    display: block;
  }
  .register-boxwrap li a {
    font-size: 12px;
    text-align: left;
  }
  .register-boxwrap li a.active {
    border: none;
    top: 0;
    background: #74b531;
    color: #fff;
  }
  #register-section.project-register {
    width: 100%;
  }
  .register-boxwrap .box-head h2 {
    font-size: 15px;
  }
  .download-btn .default-btn {
    padding: 10px;
    font-size: 12px;
    min-width: 200px;
    margin-bottom: 0;
    display: block;
  }
} */
