@font-face {
  font-family: "Myriad Pro Semibold";
  src: url('./fonts/Myriad Pro Regular.otf');
}

body {
  margin: 0;
  font-family: "Myriad Pro Regular";
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "Myriad Pro Regular";
}