.readt-more-App {
  margin: none;
}
.read-more-linkinreadmore {
  color: blue;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
}
.extra-content-readmore {
  color: gray;
  font-weight: 500;
  padding-left: 13px;
}
